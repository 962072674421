import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import Layout from '../../components/winter-2022/Layout';
import Thanks from '../../components/winter-2022/Thanks';
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const ThanksPage = () => {

  return (
    <Layout type='secundary' QR >
      <SEO title="ISLA CORONA 2022" />
      <Thanks QR/>
    </Layout>
  );
}

export default ThanksPage;
